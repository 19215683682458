import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFEmptyState from "../../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../../../RAFComponents/Utility/RAFToastComponent";
import { hasPermission } from "../../../../../RAFComponents/helpers/PermissionHelper";
import { getDisplayNameByModuleName } from "../../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  currencyFormatting,
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../../components/shared/ACLoadingPanel";
import { PayrollTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  RAFShiftActionStatus,
  RAFStatusNameWithColor,
} from "../../../../../constants/Common/Constants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import {
  RAFServiceTransactionType,
  getPayrollTransactionByShiftId,
} from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import {
  payRollDateTitleTemplate,
  shiftPayRollDurationTemplate,
} from "../BillingTimesheetTemplateHelper";
import { PayrollTransactionRow } from "./PayrollTransactionRow";
import UpdatePayrollShiftItem from "./UpdatePayrollShiftItem";

interface IProps {
  shiftUID: string;
}

interface IState {
  isLoading: boolean;
  payrollTransactions: PayrollTransactionRow[];
  showEditDialog: boolean;
  selectedItemUID: string;
}

function PayRollTimeSheetListCardContent({
  shiftUID,
}: PropsWithChildren<IProps>) {
  const moduleName = TimeSheetSummaryModuleName.PayrollTransaction;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      payrollTransactions: null,
      showEditDialog: false,
      selectedItemUID: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [shiftUID]);

  const refresh = async () => {
    setState({ isLoading: true, showEditDialog: false, selectedItemUID: null });
    //get service transactions fro the shift
    let payrollTransactions = await getPayrollTransactionByShiftId(shiftUID);
    setState({ payrollTransactions, isLoading: false });
  };

  function rowClick(selectedItemUID: string) {
    if (isNotNullAndUndefined(selectedItemUID)) {
      if (
        hasPermission(
          permissionValue,
          PayrollTransactionPermissionConstants.PayrollTransactionManageTimeSheet
        )
      ) {
        setState({ showEditDialog: true, selectedItemUID });
      } else {
        showWarningToast("You do not have permission to edit this item.");
      }
    }
  }

  // Update Dialog start

  const updateContent = () => {
    if (state.showEditDialog) {
      return (
        <UpdatePayrollShiftItem
          objectUID={state.selectedItemUID}
          onSave={onUpdate}
          onClose={closeUpdateDialog}
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const onUpdate = () => {
    refresh();
  };

  const closeUpdateDialog = () => {
    setState({ showEditDialog: false, selectedItemUID: null });
  };

  // Update Dialog end

  //console.log("payroll transactions", state.payrollTransactions);
  const getStatusDiv = (payrollTransaction: PayrollTransactionRow) => {
    const fieldValue = payrollTransaction.TimesheetStatus;
    let status = fieldValue;
    if (fieldValue === RAFShiftActionStatus.Completed) {
      status = RAFShiftActionStatus.Pending;
    }
    const colorCodeName = isNotNullAndUndefined(fieldValue)
      ? RAFStatusNameWithColor[fieldValue]
      : null;
    return (
      <span
        className="raf_badge"
        style={{
          backgroundColor: isNotNullAndUndefined(colorCodeName)
            ? hexToRGBA(colorCodeName.Color, 0.1)
            : hexToRGBA("#333", 0.1),
          color: `${
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333"
          }`,
          border: `1px solid ${hexToRGBA(
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333",
            0.1
          )}`,
        }}
      >
        {isNotNullAndUndefined(colorCodeName)
          ? colorCodeName.DisplayName
          : isNotNullAndUndefined(status)
          ? status
          : "Status"}
      </span>
    );
  };

  if (state.isLoading === false) {
    let { payrollTransactions } = state;
    if (isNotEmptyArray(payrollTransactions)) {
      return (
        <div className="overflow-auto customScrollBar px-2">
          <table className="card-table">
            <thead>
              <tr className="header">
                <th className="full-width">
                  <span className="subtitle_2 ecllipseFirstLine">Item</span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">Pay Rate</span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">Duration</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {payrollTransactions.map((payrollTransaction) => {
                return (
                  <Fragment key={payrollTransaction.UID}>
                    <tr
                      onClick={() => rowClick(payrollTransaction.UID)}
                      className="pointer"
                    >
                      <td className="full-width">
                        <div className="row g-0 gy-2">
                          <div className="col-12 mt-1">
                            <div className="subtitle_1">
                              {isNotNullAndUndefined(
                                payrollTransaction.ServiceName
                              )
                                ? payrollTransaction.ServiceName
                                : payrollTransaction.ServiceContractItem}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="body_1">
                              {payrollTransaction.PayLoading}
                            </div>
                          </div>
                          <div className="col-12 mt-1">
                            <div className="body_1">
                              <span>
                                {payRollDateTitleTemplate(payrollTransaction)}
                              </span>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex">
                              {getStatusDiv(payrollTransaction)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {isNotNullAndUndefined(
                            payrollTransaction.PayLoadingRate
                          )
                            ? currencyFormatting(
                                payrollTransaction.PayLoadingRate,
                                "USD"
                              )
                            : currencyFormatting(0, "USD")}
                        </span>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {payrollTransaction.Type ===
                          RAFServiceTransactionType.Travel
                            ? `${payrollTransaction.TravelDistance} KM`
                            : shiftPayRollDurationTemplate(payrollTransaction)}
                        </span>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFEntityProvider moduleName={moduleName}>
                <RAFEntityContext.Consumer>
                  {({ entity }) => {
                    return (
                      <div>
                        {state.showEditDialog && (
                          <DialogComponent
                            header={
                              "Update " +
                              getDisplayNameByModuleName(
                                moduleName,
                                entity.DisplayName
                              )
                            }
                            showCloseIcon
                            visible={state.showEditDialog}
                            content={updateContent.bind(this)}
                            //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                            cssClass={
                              "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                            }
                            isModal
                            target="body"
                            closeOnEscape={false}
                            close={closeUpdateDialog.bind(this)}
                            id={`dlg_create_${moduleName}`}
                            zIndex={1200}
                            open={PreventFocusOnDialogOpen}
                          ></DialogComponent>
                        )}
                      </div>
                    );
                  }}
                </RAFEntityContext.Consumer>
              </RAFEntityProvider>
            </RAFAttributeRelatedListProvider>
          </div>
        </div>
      );
    } else {
      return (
        <RAFEmptyState
          title="No Timesheets Available"
          body="There are no timesheets available at the moment. Please check back later."
        ></RAFEmptyState>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(PayRollTimeSheetListCardContent);
