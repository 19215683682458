import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFEmptyState from "../../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../../../RAFComponents/Utility/RAFToastComponent";
import { hasPermission } from "../../../../../RAFComponents/helpers/PermissionHelper";
import { getDisplayNameByModuleName } from "../../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  currencyFormatting,
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../../components/shared/ACLoadingPanel";
import { InvoiceTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  RAFButtonConstant,
  RAFShiftActionStatus,
  RAFStatusNameWithColor,
} from "../../../../../constants/Common/Constants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import {
  RAFServiceTransactionType,
  getInvoiceTransactionByShiftId,
} from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import {
  durationTemplate,
  shiftDateTitleTemplate,
} from "../BillingTimesheetTemplateHelper";
import { InvoiceTransactionRow } from "./InvoiceTransactionRow";
import UpdateInvoiceShiftItem from "./UpdateInvoiceShiftItem";
import { ServiceTransactionRow } from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import CreateInvoiceShiftItem from "./CreateInvoiceShiftItem";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";

interface IProps {
  shiftUID?: string;
  serviceTransaction?: ServiceTransactionRow;
}

interface IState {
  isLoading: boolean;
  invoiceTransactions: InvoiceTransactionRow[];
  invoiceTotal: any;
  showEditDialog: boolean;
  showAddDialog: boolean;
  selectedItemUID: string;
}

function InvoiceBillingListCardContent({
  shiftUID,
  serviceTransaction,
}: PropsWithChildren<IProps>) {
  const moduleName = TimeSheetSummaryModuleName.InvoiceTransaction;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      invoiceTransactions: null,
      invoiceTotal: 0,
      showEditDialog: false,
      selectedItemUID: null,
      showAddDialog: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [shiftUID]);

  const refresh = async () => {
    setState({
      isLoading: true,
      showEditDialog: false,
      showAddDialog: false,
      selectedItemUID: null,
    });
    //get service transactions fro the shift
    let invoiceTransactions = await getInvoiceTransactionByShiftId(shiftUID);
    let invoiceTotalNumber = 0;
    if (isNotEmptyArray(invoiceTransactions)) {
      invoiceTransactions.forEach((invoiceTransaction) => {
        const total: number = invoiceTransaction.InvoiceAmount ?? 0;
        invoiceTotalNumber = invoiceTotalNumber + total;
      });
    }

    setState({
      invoiceTransactions,
      invoiceTotal: invoiceTotalNumber,
      isLoading: false,
    });
  };

  function rowClick(selectedItemUID: string) {
    if (isNotNullAndUndefined(selectedItemUID)) {
      if (
        hasPermission(
          permissionValue,
          InvoiceTransactionPermissionConstants.InvoiceTransactionManageBilling
        )
      ) {
        setState({ showEditDialog: true, selectedItemUID });
      } else {
        showWarningToast("You do not have permission to edit this item.");
      }
    }
  }

  // Update Dialog start

  const updateContent = () => {
    if (state.showEditDialog) {
      return (
        <UpdateInvoiceShiftItem
          objectUID={state.selectedItemUID}
          onSave={onUpdate}
          onClose={closeUpdateDialog}
          serviceTransaction={serviceTransaction}
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const onUpdate = () => {
    refresh();
  };

  const closeUpdateDialog = () => {
    setState({ showEditDialog: false, selectedItemUID: null });
  };

  // Update Dialog end

  const cardFooterTemplate = (invoiceTransaction?: InvoiceTransactionRow) => {
    return (
      <div className="section__secondary__header border-top border-bottom-0 raf-sm justify-content-between">
        <div>
          <span className="profile_card_secondary_text_value">Total</span>
        </div>
        <div className="d-flex align-items-center">
          <span className="profile_card_details_label text-nowrap">
            {/* {'$' + invoiceTransaction.Total.toFixed(2)} */}
            {"$" + 100.0}
          </span>
        </div>
      </div>
    );
  };

  const shiftUnit = (rateType) => {
    let retVal;
    if (rateType === "Hourly Rate" || rateType === "H") {
      retVal = "Hourly";
    } else if (rateType === "Per Day") {
      retVal = "Day";
    } else {
      retVal = rateType;
    }
    return retVal;
  };

  const getBillingStatusDiv = (invoiceTransaction: InvoiceTransactionRow) => {
    const fieldValue = invoiceTransaction.BillingStatus;
    let status = fieldValue;
    if (fieldValue === RAFShiftActionStatus.Completed) {
      status = RAFShiftActionStatus.Pending;
    }
    const colorCodeName = isNotNullAndUndefined(fieldValue)
      ? RAFStatusNameWithColor[fieldValue]
      : null;
    return (
      <span
        className="raf_badge"
        style={{
          backgroundColor: isNotNullAndUndefined(colorCodeName)
            ? hexToRGBA(colorCodeName.Color, 0.1)
            : hexToRGBA("#333", 0.1),
          color: `${
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333"
          }`,
          border: `1px solid ${hexToRGBA(
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333",
            0.5
          )}`,
        }}
      >
        {isNotNullAndUndefined(colorCodeName)
          ? colorCodeName.DisplayName
          : isNotNullAndUndefined(status)
          ? status
          : "BillingStatus"}
      </span>
    );
  };

  //Add invoice item starts
  const addInvoiceItemDialogOpen = () => {
    setState({ showAddDialog: true });
  };

  const addInvoiceItemDialogClose = () => {
    setState({ showAddDialog: false });
  };

  const addInvoiceItemDialogContent = () => {
    if (state.showAddDialog) {
      return (
        <CreateInvoiceShiftItem
          onSave={onUpdate}
          onClose={addInvoiceItemDialogClose}
          serviceTransaction={serviceTransaction}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  //Add invoice item ends

  if (state.isLoading === false) {
    let { invoiceTransactions } = state;

    return (
      <div className="overflow-auto customScrollBar px-2">
        {/*commented by hari to hide add manual invoice billing*/}
        {/* {hasPermission(
          permissionValue,
          InvoiceTransactionPermissionConstants.InvoiceTransactionManageBilling
        ) && (
          <div className="d-flex justify-content-end">
            <RAFButtonComponent
              action={RAFButtonConstant.Add}
              iconPosition="right"
              btnContent={"Add"}
              className="btn_brand_primary semi_dark ms-2 mb-2"
              showIcon={true}
              onClick={addInvoiceItemDialogOpen}
            />
          </div>
        )} */}
        {isNotEmptyArray(invoiceTransactions) ? (
          <>
            <table className="card-table">
              <thead>
                <tr className="header">
                  <th className="full-width">
                    <span className="subtitle_2 ecllipseFirstLine">Item</span>
                  </th>
                  <th>
                    <span className="subtitle_2 ecllipseFirstLine">Unit</span>
                  </th>
                  <th>
                    <span className="subtitle_2 ecllipseFirstLine">
                      Duration
                    </span>
                  </th>
                  <th>
                    <span className="subtitle_2 ecllipseFirstLine">Rate</span>
                  </th>
                  <th>
                    <span className="subtitle_2 ecllipseFirstLine">
                      Invoice Amount
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceTransactions.map((invoiceTransaction, index) => {
                  return (
                    <Fragment key={invoiceTransaction.UID}>
                      <tr
                        onClick={() => rowClick(invoiceTransaction.UID)}
                        className="pointer"
                      >
                        <td className="full-width">
                          <div className="row g-0 gy-2">
                            {/* <div className="col-12">
                      <div className="subtitle_1">
                        <span>{invoiceTransaction.ServiceCode}</span>
                      </div>
                    </div> */}
                            <div className="col-12 mt-1">
                              <div className="body_1">
                                <span>{invoiceTransaction.ServiceName}</span>
                              </div>
                            </div>
                            <div className="col-12 mt-1">
                              <div className="body_1">
                                <span>
                                  {shiftDateTitleTemplate(invoiceTransaction)}
                                </span>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex">
                                {getBillingStatusDiv(invoiceTransaction)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="body_2 ecllipseFirstLine">
                            {shiftUnit(invoiceTransaction.RateType)}
                          </span>
                        </td>
                        <td>
                          <span className="body_2 ecllipseFirstLine">
                            {invoiceTransaction.Type ===
                            RAFServiceTransactionType.Travel
                              ? `${invoiceTransaction.TravelDistance} KM`
                              : durationTemplate(
                                  "Duration",
                                  invoiceTransaction
                                )}
                          </span>
                        </td>
                        <td>
                          <span className="body_2 ecllipseFirstLine">{`${currencyFormatting(
                            invoiceTransaction.Rate,
                            "USD"
                          )}`}</span>
                        </td>
                        <td>
                          <span className="body_2 ecllipseFirstLine">{`${currencyFormatting(
                            invoiceTransaction.InvoiceAmount,
                            "USD"
                          )}`}</span>
                        </td>
                        {/* <td className="footer_td">
                  {getBillingStatusDiv(invoiceTransaction)}
                </td> */}
                      </tr>
                      {index === invoiceTransactions.length - 1 && (
                        <tr
                          key={`${invoiceTransaction.UID}_surface_neutral_base`}
                          className="surface_neutral_base"
                        >
                          <td className="full-width"></td>
                          <td className="w-auto"></td>
                          <td className="w-auto"></td>
                          <td>
                            <span className="body_2 ecllipseFirstLine">
                              {"Total"}
                            </span>
                          </td>
                          <td>
                            <span className="body_2_dark ecllipseFirstLine">{`${currencyFormatting(
                              state.invoiceTotal,
                              "USD"
                            )}`}</span>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
            <div>
              {state.showEditDialog && (
                <DialogComponent
                  header={"Update Invoice Item"}
                  showCloseIcon
                  visible={state.showEditDialog}
                  content={updateContent.bind(this)}
                  //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                  cssClass={
                    "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                  }
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={closeUpdateDialog.bind(this)}
                  id={`dlg_create_${moduleName}`}
                  zIndex={1200}
                  open={PreventFocusOnDialogOpen}
                ></DialogComponent>
              )}
            </div>
          </>
        ) : (
          <RAFEmptyState
            // title="No Bills Available" //commented by Hari
            // body="Currently, there are no bills available. Please check back later."
            title="No invoice items available"
            body="Currently, there are no invoice items available. Please check back later."
          ></RAFEmptyState>
        )}

        {state.showAddDialog && (
          <DialogComponent
            header={"Create Invoice Item"}
            showCloseIcon
            visible={state.showAddDialog}
            content={addInvoiceItemDialogContent.bind(this)}
            //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
            cssClass={
              "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            }
            isModal
            target="body"
            closeOnEscape={false}
            close={addInvoiceItemDialogClose.bind(this)}
            id={`dlg_create_${moduleName}`}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}
export default React.memo(InvoiceBillingListCardContent);
